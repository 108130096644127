<template>
  <div>
    <maca-modal titulo="Datos de Empleado" :solicitar-confirmacion-close="false" ref="modal">
      <span v-if="cargando">Cargando...</span>
      <div v-else>
        <table v-if="datos" class="tabla-info">
          <tbody>
            <tr>
              <th>Nombre y Apellido</th>
              <td v-text="datos.nombre + ' ' + datos.apellido"></td>
            </tr>
            <tr>
              <th>DNI</th>
              <td v-text="datos.dni"></td>
            </tr>
            <tr>
              <th>Email</th>
              <td v-text="datos.email"></td>
            </tr>
            <tr>
              <th>Calle</th>
              <td v-text="datos.calle
              + (datos.nro != null ? ' ' + datos.nro : '')
              + (datos.piso != null ? ', ' + datos.piso : '')
              + (datos.dpto != null ? ', ' + datos.dpto : '')">
              </td>
            </tr>
            <tr>
              <th>Provincias</th>
              <td v-if="datos.detalleProvincia != null">
                <table class="detalleProvincia">
                  <tr
                      v-for="(item, index) in datos.detalleProvincia"
                      :key="index"
                  >
                    <td v-text="item.provincia"></td>
                  </tr>
                </table>
              </td>
              <td v-else></td>
            </tr>
            <tr>
              <th>Zonas</th>
              <td v-if="datos.detalleZona != null">
                <table class="detalleZona">
                  <tr
                      v-for="(item, index) in datos.detalleZona"
                      :key="index"
                  >
                    <td v-text="item.zona"></td>
                  </tr>
                </table>
              </td>
              <td v-else></td>
            </tr>
          </tbody>
        </table>
      </div>
    </maca-modal>
  </div>
</template>

<style lang="css" scoped>
.detalleProvincia td {
  padding: 5px;
  min-width: 50px;
  min-height: 30px;
  text-align: center;
  border: 1px solid;
}

.detalleProvincia .header {
  background: #ddf;
  font-weight: bold;
}
.detalleZona td {
  padding: 5px;
  min-width: 50px;
  min-height: 30px;
  text-align: center;
  border: 1px solid;
}

.detalleZona .header {
  background: #ddf;
  font-weight: bold;
}
</style>

<script>
export default {
  name: "ver",
  data() {
    return {
      id: null,
      datos: null,
      cargando: false,
    };
  },
  methods: {
    abrir(id) {
      this.id = id;
      this.$refs.modal.abrir();
      this.getDatos();
    },
    async getDatos() {
      this.cargando = true;

      let url = this.$api.URL + "/empleado/obtenerDatos?empleadoID=" + this.id;

      let respuestaApi = await this.$api.get(url, this.$usuarioToken());

      this.cargando = false;

      if (respuestaApi != null) {
        this.datos = respuestaApi;
      }
    },
  },
};
</script>
