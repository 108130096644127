<template>
  <div style="display: flex">
    <maca-select-box
      v-model="provincia"
      :url="urlProvincia"
      :necesitaParams="true"
      placeholder="Provincia"
      :getParams="
        query => {
          return 'paisID=1';
        }
      "
      style="margin-right: 10px"
      :disabled="disabled"
      @change="$emit('input', provincia);"
    ></maca-select-box>
  </div>
</template>

<script>
export default {
  name: "ubicacion",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      provincia: null,

      urlProvincia: this.$api.URL + "/provincia/obtenerTodos"
    };
  },
  methods: {},
  watch: {
    value() {
      if (this.value != null) {
        setTimeout(() => {
          this.provincia = this.value[0];
        }, 200);
      } else {
        this.provincia = null;
      }

      this.$emit("change");
    }
  }
};
</script>
