var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex" } },
    [
      _c("maca-select-box", {
        staticStyle: { "margin-right": "10px" },
        attrs: {
          url: _vm.urlProvincia,
          necesitaParams: true,
          placeholder: "Provincia",
          getParams: function(query) {
            return "paisID=1"
          },
          disabled: _vm.disabled
        },
        on: {
          change: function($event) {
            return _vm.$emit("input", _vm.provincia)
          }
        },
        model: {
          value: _vm.provincia,
          callback: function($$v) {
            _vm.provincia = $$v
          },
          expression: "provincia"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }