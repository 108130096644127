<template>
  <div>
    <div class="contenedor-titulo">
      <div class="titulo">
        <h3>
          <i class="el-icon-s-custom"></i>Empleados
        </h3>
      </div>
      <div class="botones">
        <el-button
          type="primary"
          icon="el-icon-plus"
          plain
          @click="$refs.modalNuevo.abrir()"
        >Nuevo Empleado</el-button>
      </div>
    </div>
    <el-card :body-style="{ padding: '20px' }">
      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column :min-width="120">
            <template slot="header">
              <maca-datatable-filtrar label="Nombre" v-model="filtroNombre"></maca-datatable-filtrar>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.nombre + ' ' + props.row.apellido"></span>
            </template>
          </el-table-column>
          <el-table-column label="Email">
            <template slot="header">
              <maca-datatable-filtrar label="Email" v-model="filtroEmail"></maca-datatable-filtrar>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.email"></span>
            </template>
          </el-table-column>
          <el-table-column label="DNI">
            <template slot="header">
              <maca-datatable-filtrar label="DNI" v-model="filtroDni"></maca-datatable-filtrar>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.dni"></span>
            </template>
          </el-table-column>
          <el-table-column label="Provincia" :min-width="80">
            <template slot="header">
              <maca-datatable-filtrar-select label="Provincia" v-model="filtroProvincia" :urlSelect="urlProvincia"></maca-datatable-filtrar-select>
            </template>
            <template slot-scope="props">
              <el-tooltip v-if="props.row.detalleProvincia != null" class="item" effect="dark" placement="bottom-start">
                <div slot="content">
                  <span v-for="(item, index) in props.row.detalleProvincia" :key="index">
                  <span>{{item.provincia}}</span>
                  <span
                      v-if="index != props.row.detalleProvincia.length - 1"
                      style="margin-right: 7px"
                  >,</span>
                  </span>
                </div>
                <el-tag style="margin-top: 3px; margin-bottom: 3px" type="info">{{props.row.detalleProvincia.length}} provincias</el-tag>
              </el-tooltip>
              <el-tag v-else style="margin-top: 3px; margin-bottom: 3px" type="info">-</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="Zonas">
            <template slot="header">
              <maca-datatable-filtrar-select label="Zona" v-model="filtroZona" :urlSelect="urlZona"></maca-datatable-filtrar-select>
            </template>
            <template slot-scope="props">
              <el-tooltip v-if="props.row.detalleZona != null" class="item" effect="dark" placement="bottom-start">
                <div slot="content">
                  <span v-for="(item, index) in props.row.detalleZona" :key="index">
                  <span>{{item.zona}}</span>
                  <span
                      v-if="index != props.row.detalleZona.length - 1"
                      style="margin-right: 7px"
                  >,</span>
                  </span>
                </div>
                <el-tag style="margin-top: 3px; margin-bottom: 3px" type="info">{{props.row.detalleZona.length}} zonas</el-tag>
              </el-tooltip>
              <el-tag v-else style="margin-top: 3px; margin-bottom: 3px" type="info">-</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="Ver" width="70">
            <template slot-scope="props">
              <el-button
                  type="success"
                  size="small"
                  @click="$refs.modalVer.abrir(props.row.id)"
                  plain
                  round
              >
                <i class="el-icon-view"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="Editar" width="70">
            <template slot-scope="props">
              <el-button
                type="warning"
                size="small"
                @click="$refs.modalModificar.abrir(props.row.id)"
                plain
                round
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="Borrar" width="70">
            <template slot-scope="props">
              <el-button type="danger" size="small" @click="eliminar(props.row.id)" plain round>
                <i class="el-icon-delete"></i>
              </el-button>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
    <modal-ver ref="modalVer" @actualizar-tabla="actualizarTabla = true"></modal-ver>
    <modal-nuevo ref="modalNuevo" @actualizar-tabla="actualizarTabla = true"></modal-nuevo>
    <modal-modificar ref="modalModificar" @actualizar-tabla="actualizarTabla = true"></modal-modificar>
  </div>
</template>

<style></style>

<style scoped></style>

<script>
import ModalVer from "./modales/ver";
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";

export default {
  name: "home",
  components: {
    ModalVer,
    ModalNuevo,
    ModalModificar,
  },
  data() {
    return {
      urlTabla: this.$api.URL + "/empleado/obtenerTodos",
      paramsTabla: [],
      actualizarTabla: true,
      bloquearTabla: true,

      urlProvincia: this.$api.URL + "/provincia/obtenerTodos",
      urlZona: this.$api.URL + "/zona/obtenerTodosSelect",

      filtroNombre: null,
      filtroEmail: null,
      filtroDni: null,
      filtroProvincia: null,
      filtroZona: null,
    };
  },
  methods: {
    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar el empleado."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { empleadoID: id };

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/empleado/eliminar",
        params,
        this.$usuarioToken()
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Empleado borrado con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
  },
  watch: {
    filtroNombre() {
      if (this.filtroNombre != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "nombre",
          this.filtroNombre
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "nombre");
      }

      this.actualizarTabla = true;
    },
    filtroDni() {
      if (this.filtroDni != null) {
        this.$common.agregarUrlParam(
            this.paramsTabla,
            "dni",
            this.filtroDni
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "dni");
      }

      this.actualizarTabla = true;
    },
    filtroEmail() {
      if (this.filtroEmail != null) {
        this.$common.agregarUrlParam(
            this.paramsTabla,
            "email",
            this.filtroEmail
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "email");
      }

      this.actualizarTabla = true;
    },
    filtroZona() {
      if (this.filtroZona != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "zonaID",
          JSON.stringify(this.filtroZona.id)
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "zonaID");
      }

      this.actualizarTabla = true;
    },
    filtroProvincia() {
      if (this.filtroProvincia != null) {
        this.$common.agregarUrlParam(
            this.paramsTabla,
            "provinciaID",
            JSON.stringify(this.filtroProvincia.id)
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "provinciaID");
      }

      this.actualizarTabla = true;
    },
  },
};
</script>
